/**
 * @file Helper method to bridge url clicks using postMessage
 */
import appCan from '@@/bits/app_can'
import { buildUrlFromPath } from '@@/bits/location'
import { openLink } from '@@/native_bridge/actions'
import postMessage from '@@/native_bridge/post_message'
import { LinkType } from '@@/native_bridge/types'

export function bridgeUrlClick(event: Event, url: string, bridgeTarget: string): void {
  if (!appCan('postMessage')) {
    return
  }
  event.preventDefault()

  // prepend host and protocol to links if they are not already there
  if (!/^https?:\/\//.test(url) && !url.startsWith('//')) {
    url = buildUrlFromPath(url)
  }
  postMessage(openLink({ url, linkType: bridgeTarget as LinkType }))
}
